import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  transaction: null,
  history: [],
  isLoading: false,
  isDeleting: false,
  error: '',
};

const floatCashTransactionSlice = createSlice({
  name: 'floatCashTransaction',
  initialState: initialState,
  reducers: {
    setFloatCashTransaction: (state, { payload }) => {
      state.transaction = payload;
      state.isLoading = false;
    },
    setFloatCashAuditHistory: (state, { payload }) => {
      state.history = payload;
      state.isLoading = false;
    },
    resetState: (state) => {
      state.transaction = null;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state, { payload }) => {
      state.error = '';
      state.isLoading = payload;
    },
    setDeleting: (state, { payload }) => {
      state.error = '';
      state.isDeleting = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isDeleting = false;
    },
  },
});

export const floatCashTransactionFetch = (uuid) => (dispatch) => {
  console.log('>>> Getting transaction: ', uuid);
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/transaction/${uuid}`)
    .then((response) => dispatch(setFloatCashTransaction(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const floatCashAuditHistoryFetch = (uuid) => (dispatch) => {
  getApi()
    .get(`/api/float-cash/transaction/${uuid}/history`)
    .then((response) =>
      dispatch(setFloatCashAuditHistory(response.data?.data || []))
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const deleteTransaction = (uuid, transactionType) => (dispatch) => {
  console.log('>>> Deleting transaction: ', uuid);
  dispatch(setDeleting(true));
  getApi()
    .delete(`/api/float-cash/transaction/${uuid}`)
    .then(() => {
      if (transactionType === 'FLOAT_OUT' || transactionType === 'REMITTANCE') {
        dispatch(push('/float-cash/disbursements'));
      } else {
        dispatch(push('/float-cash/cash'));
      }
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setFloatCashTransaction,
  setFloatCashAuditHistory,
  resetState,
  setLoading,
  setDeleting,
  setError,
} = floatCashTransactionSlice.actions;

export const floatCashTransactionReducer = floatCashTransactionSlice.reducer;
