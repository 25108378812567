import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  transaction: null,
  attachmentUrl: null,
  isUploadingFiles: false,
  isSubmitting: false,
  isLoading: false,
};

export const floatCashTransactionEditFormSlice = createSlice({
  name: 'floatCashTransactionEditForm',
  initialState,
  reducers: {
    setFloatCashTransaction: (state, { payload }) => {
      state.transaction = payload;
      state.isLoading = false;
    },
    setUploadFile: (state, { payload }) => {
      state.attachmentUrl = payload.url;
      state.isUploadingFiles = false;
    },
    removeUploadFile: (state) => {
      console.log('>>> Removing uploaded file');
      state.attachmentUrl = null;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setUploadingFiles: (state) => {
      state.error = '';
      state.isUploadingFiles = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      stopLoading();
    },
    setSubmitting: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    stopLoading: (state) => {
      state.error = '';
      state.isLoading = false;
      state.isUploadingFiles = false;
      state.isSubmitting = false;
    },
  },
});

export const floatCashTransactionFetch = (uuid) => (dispatch) => {
  console.log('>>> Getting transaction: ', uuid);
  dispatch(setLoading(true));
  getApi()
    .get(`/api/float-cash/transaction/${uuid}`)
    .then((response) => dispatch(setFloatCashTransaction(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const cashTransactionUpdate = (uuid, formData) => (dispatch) => {
  dispatch(setSubmitting(true));
  getApi()
    .patch(`/api/float-cash/transaction/${uuid}`, formData)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(push(`/float-cash/cash/${uuid}`));
    })
    .catch((err) => {
      // Extract the error message from the error response
      const errorMessage = err.response?.data?.message || getApiError(err);
      dispatch(stopLoading());
      dispatch(setError(errorMessage));
    });
};

export const uploadAttachmentsFile = (file) => (dispatch) => {
  dispatch(setUploadingFiles(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch(setUploadFile(res.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const {
  setFloatCashTransaction,
  setError,
  setSubmitting,
  setLoading,
  setUploadingFiles,
  removeUploadFile,
  setUploadFile,
  stopLoading,
} = floatCashTransactionEditFormSlice.actions;

export const floatCashTransactionEditFormReducer =
  floatCashTransactionEditFormSlice.reducer;
