import { dateFormat, getApi, numberFormat, titleCase } from '@/common';

export const getCreatedAt = (request) => dateFormat(request?.createdAt) ?? '-';

export const getUpdatedAt = (request) => dateFormat(request?.updatedAt) ?? '-';

export const getAmount = (request) => numberFormat(request.amount);

export const getTransactionStatuses = () => [
  'succeeded',
  'reversed',
  'reimbursed',
];

export const getRequestStatuses = () => [
  'APPROVED',
  'PENDING',
  'DISBURSED',
  'PROCESSING',
  'CANCELLED',
  'FAILED',
  'REJECTED',
];

export const getStatus = (status) => {
  return status.toLowerCase();
};

export const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'succeeded':
      return '#317159';
    case 'reversed':
      return '#317159';
    case 'reimbursed':
      return '#317159';
    case 'approved':
      return '#317159';
    case 'pending':
      return '#4a4a4a';
    case 'processing':
      return '#651fff';
    case 'disbursed':
      return '#eafded';
    case 'cancelled':
      return '#eafded';
    case 'failed':
      return '#bf360c';
    case 'rejected':
      return '#bf1100';
    default:
      return 'white';
  }
};

export const getStatusBackground = (status) => {
  switch (status?.toLowerCase()) {
    case 'succeeded':
      return '#DCF2EA';
    case 'reversed':
      return '#DCF2EA';
    case 'reimbursed':
      return '#DCF2EA';
    case 'approved':
      return '#DCF2EA';
    case 'pending':
      return '#ffcf44';
    case 'processing':
      return '#d1c4e9';
    case 'disbursed':
      return '#51af5d';
    case 'cancelled':
      return '#bf1100';
    case 'failed':
      return '#ffccbc';
    case 'rejected':
      return '#ffcec9';
    default:
      return 'darkgrey';
  }
};

export const getRequestType = (type) => type?.toLowerCase();

export const getRequestTypeColor = (type) => {
  switch (type?.toLowerCase()) {
    case 'float':
      return '#DCF2EA';
    case 'cash':
      return 'white';
    default:
      return 'darkgrey';
  }
};

export const getRequestTypeBackground = (type) => {
  switch (type?.toLowerCase()) {
    case 'float':
      return '#317159';
    case 'cash':
      return '#4a4a4a';
    default:
      return 'white';
  }
};

export const getFloatCashTypes = () => [
  'CASH_OUT',
  'CASH_IN',
  'DEPOSIT',
  'WITHDRAWAL',
];

export const getFloatTypes = () => ['FLOAT_OUT'];

export const getFloatCashTypeText = (type) => {
  switch (type) {
    case 'FLOAT_IN':
      return 'Reversal';
    case 'FLOAT_OUT':
      return 'Disburse';
    case 'CASH_OUT':
      return 'Issuance';
    case 'CASH_IN':
      return 'Collection';
    case 'DEPOSIT':
      return 'Deposit';
    case 'WITHDRAWAL':
      return 'Cash Issued (office)';
    default:
      return type;
  }
};

export const getFloatCashTransactionTypeTitle = (transaction) => {
  switch (transaction.transactionType) {
    case 'FLOAT_IN':
      return 'Float Reversal';
    case 'FLOAT_OUT':
      return 'Float Disbursal';
    case 'CASH_OUT':
      return 'Cash Issued';
    case 'CASH_IN':
      return 'Cash collection';
    case 'DEPOSIT':
      return 'Cash deposit';
    case 'WITHDRAWAL':
      return 'Cash Issued (office)';
    default:
      return transaction.transactionType;
  }
};

export const getFloatCashFromAccount = (transaction) =>
  transaction?.fromAccount?.agentName ?? '-';

export const getFloatCashToAccount = (transaction) =>
  transaction?.toAccount?.agentName ?? '-';

export const getFloatCashToAccountSignature = (transaction) =>
  transaction?.toAccountSignatureUrl;

export const getFloatCashFromAccountSignature = (transaction) =>
  transaction?.fromAccountSignatureUrl;

export const getFloatCashFieldAgentName = (transaction) => {
  return transaction?.fieldAgent?.name || '-';
};

export const getFloatCashFieldAgentNumber = (transaction) => {
  return transaction?.fieldAgent?.phoneNumber || '-';
};

export const getFloatCashDebtThreshold = (transaction) => {
  return numberFormat(transaction?.dailyDebtThreshold || 0) || '-';
};

export const getFloatCashRequestLimitThreshold = (transaction) => {
  return numberFormat(transaction?.dailyFloatRequestLimit || 0) || '-';
};

export const getFloatCashRequestCashInHandThreshold = (transaction) => {
  return numberFormat(transaction?.cashThreshold || 0) || '-';
};

export const getFloatCashRequestThresholdAutoApprove = (transaction) => {
  return transaction?.autoApproveFloatRequest === true ? 'On' : 'Off';
};

export const getFloatCashSubmittedBy = (transaction) => {
  return transaction.user?.fieldAgent?.name || '...';
};

export const getFloatCashAgentName = (transaction) => {
  return transaction?.agent?.agentName || '-';
};

export const splitFloatCashAgentsName = (transaction) => {
  const agents = transaction?.agents || '';
  return agents.split(',').map((value) => value.trim());
};

export const getAgentSignature = (transaction) => {
  switch (transaction.transactionType) {
    case 'CASH_OUT':
    case 'DEPOSIT':
      return getFloatCashToAccountSignature(transaction);
    default:
      return getFloatCashFromAccountSignature(transaction);
  }
};

export const getFloatCashCollectedFrom = (transaction) => {};

export const getFieldAgentSignature = (transaction) => {
  switch (transaction.transactionType) {
    case 'CASH_OUT':
    case 'DEPOSIT':
      return getFloatCashFromAccountSignature(transaction);
    default:
      return getFloatCashToAccountSignature(transaction);
  }
};

export const getFloatCashType = (transaction) =>
  getFloatCashTypeText(transaction?.transactionType);

export const getFloatCashCurrency = (transaction) => transaction?.currency;

export const getFloatCashAmount = (transaction) =>
  numberFormat(transaction?.amount);

export const getFloatCashAgentNumber = (transaction) =>
  transaction?.phoneNumber ?? '-';

export const getFloatCashAgentTDR = (transaction) =>
  transaction?.extraInfo?.agentTdr ?? '-';

export const getFloatCashAgentNumberFromSimNumbers = (transaction) =>
  transaction?.agent?.simNumbers[0]?.phoneNumber ?? '-';

export const getFloatCashComments = (transaction) =>
  (transaction.comment || ((transaction?.comments ?? []).join('\n') ?? '-')) ??
  '-';

export const getFloatCashBankName = (transaction) =>
  transaction?.bankAccount?.bankName || '-';

export const getFloatCashConfirmation = (transaction) => {
  switch (transaction?.transactionType) {
    case 'FLOAT_IN':
    case 'FLOAT_OUT':
    case 'CASH_OUT':
    case 'CASH_IN':
    case 'WITHDRAWAL':
    default:
      return [];
    case 'DEPOSIT':
      return transaction?.attachments ?? [];
  }
};

const getFloatCashSignature = (transaction, accountIdType) => {
  const field =
    transaction?.fromAccountIdType === accountIdType
      ? 'fromAccountSignatureUrl'
      : transaction?.toAccountIdType === accountIdType
        ? 'toAccountSignatureUrl'
        : null;

  return transaction?.[field] ? [transaction[field]] : [];
};

export const getFloatCashAgentSignature = (transaction) => {
  return getFloatCashSignature(transaction, 'Agent');
};

export const getFloatCashFieldAgentSignature = (transaction) => {
  return getFloatCashSignature(transaction, 'FieldAgent');
};

export const getVarianceTransactionType = (transaction) => {
  return transaction.varianceType;
};

export const getVarianceFieldAgentName = (transaction) => {
  return transaction.fieldAgent?.name;
};
export const getVarianceAgents = (transaction) => {
  return transaction.agents;
};

export const getVarianceAmount = (transaction) =>
  `${transaction.currency}${numberFormat(transaction?.amount)}`;

export const getVarianceCashPaidAccount = (transaction) => {
  if (transaction?.transactionType === 'DEBIT_VARIANCE')
    return transaction?.toAccount?.accountHolderName;
  else if (transaction?.transactionType === 'CREDIT_VARIANCE')
    return transaction?.fromAccount?.accountHolderName;
};

export const getVarianceAmountPaid = (transaction) =>
  `${transaction.currency}${numberFormat(transaction?.amountPaid)}`;

export const getVarianceComments = (transaction) =>
  (transaction?.comments ?? []).join('\n') ?? '-';

export const getVariancePaymentStatus = (transaction) => {
  const paymentStatus = transaction.paymentStatus || '';
  return paymentStatus
    .split('_')
    .map((value) => titleCase(value))
    .join(' ');
};

export const amountToNumberOrZero = (amount) => {
  let number = typeof amount === 'number' ? amount : Number(amount);
  return isNaN(number) ? 0 : number;
};

export const toSingularPluralValueTitle = (value, singularText, pluralText) => {
  return amountToNumberOrZero(value) === 1 ? singularText : pluralText;
};

export const getLoggedInUser = () => {
  const sUser = localStorage.getItem('user');
  try {
    return sUser != null ? JSON.parse(sUser) : undefined;
  } catch (error) {
    console.error('Failed to getLoggedInUser', { error });
    return undefined;
  }
};

export const isNullOrEmpty = (value) => {
  return !value || value?.trim() === '-';
};

export const fetchMyFieldAgent = () => {
  return new Promise((accept, reject) => {
    const loggedInUser = getLoggedInUser();
    const fieldAgentUuid = loggedInUser?.fieldAgentUuid;
    if (!fieldAgentUuid) {
      return accept({
        uuid: loggedInUser.uuid,
        name: loggedInUser.email,
        email: loggedInUser.email,
        phoneNumber: loggedInUser.phoneNumber,
      });
    }
    getApi()
      .get(`api/field-agents/${fieldAgentUuid}`)
      .then((response) => {
        accept({
          uuid: response.data.uuid,
          name: response.data.name,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
        });
      })
      .catch((err) => reject(err));
  });
};

export const getBalanceSummaryFormula = (jsonText) => {
  const array = [];
  try {
    const parsedJson = JSON.parse(jsonText);
    Object.keys(parsedJson).forEach((key) => {
      array.push({ key, value: parsedJson[key] });
    });
    console.log('>>> Balance summary array: ', array);
  } catch (error) {
    console.error('Failed to parse: ', { jsonText, error });
  }
  return array;
};

export const stringToBool = (string) => {
  return string === 'true' ? true : string === 'false' ? false : string;
};

export const getVarianceTypes = () => ['credit', 'debit'];

export const getPaymentStatusesForSelect = () => [
  ['Paid', 'paid'],
  ['Partial', 'partial'],
  ['Not Paid', 'not_paid'],
];

export const getFloatUtilization = (request) => {
  if (!request?.agent?.additionalData?.floatUtilization) {
    return '-';
  } else {
    return Math.round(
      parseFloat(`${request?.agent?.additionalData?.floatUtilization}`)
    );
  }
};

export const getCommissionBuckets = () => ['HIGH', 'MEDIUM', 'LOW', 'INACTIVE'];

export const getCommissionBucket = (commissionBucket) => {
  return commissionBucket?.toLowerCase() ?? '-';
};

export const getCommissionBucketColor = (commissionBucket) => {
  switch (commissionBucket?.toLowerCase()) {
    case 'high':
      return '#317159';
    case 'medium':
      return '#ffe4cc';
    case 'low':
      return '#eafded';
    default:
      return 'white';
  }
};

export const getCommissionBucketBackground = (commissionBucket) => {
  switch (commissionBucket?.toLowerCase()) {
    case 'high':
      return '#DCF2EA';
    case 'medium':
      return '#ff881f';
    case 'low':
      return '#bf1100';
    default:
      return 'darkgrey';
  }
};
