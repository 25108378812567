import { capitalize, dateFormat, numberFormat } from '@/common';
import { FEATURES, hasFeature } from '@/common/features';

export const getStatusColor = (transaction) => {
  switch (transaction.status) {
    case 'pending':
      return '#F8E3DA';
    case 'succeeded':
      return '#BAE5D5';
    case 'failed':
      return '#F9DADA';
    default:
      return 'grey';
  }
};

export const getStatusText = (transaction) => {
  if (transaction.status === 'pending') {
    return '#996A13';
  } else if (transaction.status === 'succeeded') {
    return '#317159';
  } else if (transaction.status === 'failed') {
    return '#A73636';
  }
};

export const toTitleCase = (value) => {
  if (!value || value.length < 1) return value;
  return capitalize(value);
};

export const getStatus = (transaction) => {
  return transaction.status ? toTitleCase(transaction.status) : 'unknown';
};

export const getTelcoColor = (transaction) => {
  if (transaction.telco === 'UG_MTN') {
    return '#ffcf44 ';
  } else if (transaction.telco === 'UG_AIRTEL') {
    return '#ffcec9';
  }
};

export const getTelcoText = (transaction) => {
  if (transaction.telco === 'UG_MTN') {
    return '#4a4a4a';
  } else if (transaction.telco === 'UG_AIRTEL') {
    return '#bf1100';
  }
};

export const getTelcoNameLabel = (telco) => {
  if (telco && telco.indexOf('_') > -1) {
    return telco.split('_').reverse().join(' ');
  }
  return telco;
};

export const getTelcoName = (transaction) => {
  return getTelcoNameLabel(transaction.telco);
};

export const getCategoryColor = (transaction) => {
  switch (transaction.category) {
    case 'deposit':
      return '#DCF2EA';
    case 'withdrawal':
      return '#D6E0FF';
    case 'airtime':
    case 'bundle':
    case 'customer_registration':
      return '#E0B0FF';
    case 'float_transfer':
      return '#E7E4F9';
    case 'paybill':
    case 'paybill-ende':
      return '#FFEFD2';
    case 'loan_repayment':
      return '#FFD4B2';
    case 'remittance-ria':
    case 'paybill-epal':
      return '#ff6608';
    case 'remittance-moneygram':
      return '#d9252a';
    case 'paybill-uedcl':
    case 'paybill-light':
    case 'remittance-bnb':
      return '#ff6608';
    case 'remittance-western-union':
      return '#ffde08';
    default:
      return '#edeff5';
  }
};

export const getCategoryText = (transaction) => {
  switch (transaction.category) {
    case 'deposit':
      return '#317159';
    case 'withdrawal':
      return '#2952CC';
    case 'airtime':
    case 'bundle':
    case 'customer_registration':
      return '#673147';
    case 'float_transfer':
      return '#6E62B6';
    case 'paybill':
    case 'paybill-ende':
      return '#66460D';
    case 'loan_repayment':
      return '#85586F';
    case 'remittance-ria':
    case 'remittance-bnb':
    case 'remittance-moneygram':
    case 'paybill-epal':
    case 'paybill-uedcl':
    case 'paybill-light':
      return '#fff0c7';
    default:
      return '#474d66';
  }
};

export const getCategoryTextLabel = (category) => {
  switch (category) {
    case 'airtime':
      return 'Airtime';
    case 'customer_registration':
      return 'Customer registration';
    case 'balance':
      return 'Balance';
    case 'commission':
      return 'Commission';
    case 'commission_transfer':
      return 'Commission Transfer';
    case 'deposit':
      return 'Deposit';
    case 'loyalty-payment':
      return 'Loyalty Payment';
    case 'loyalty-topup':
      return 'Loyalty Topup';
    case 'withdrawal':
      return 'Withdrawal';
    case 'transfer':
      return 'Float Transfer Out';
    case 'transfer_in':
      return 'Float Transfer In';
    case 'paybill':
      return 'Bill Pay';
    case 'paybill-dstv':
      return 'Paybill (DSTV)';
    case 'paybill-nwsc':
      return 'Paybill (NWSC)';
    case 'paybill-startimes':
      return 'Paybill (Startimes)';
    case 'paybill-tugende':
      return 'Paybill (Tugende)';
    case 'paybill-umeme':
      return 'Paybill (UMEME)';
    case 'paybill-yaka':
      return 'Paybill (Yaka)';
    case 'paybill-uedcl':
      return 'Paybill (UEDCL)';
    case 'paybill-light':
      return 'Paybill (UEDCL Light)';
    case 'paybill-ura':
      return 'Paybill (Ura)';
    case 'paybill-water':
      return 'Paybill (Water)';
    case 'paybill-ende':
      return 'Paybill (ENDE)';
    case 'paybill-epal':
      return 'Paybill (EPAL)';
    case 'SC Transfer Comm To SC':
      return 'SC Transfer Comm To SC';
    case 'loan_repayment':
      return 'Loan Repayment';
    case 'exchange':
      return 'Float Exchange';
    case 'bundle':
      return 'Bundle';
    case 'remittance-bnb':
      return 'BNB Remit';
    case 'remittance-moneygram':
      return 'MoneyGram';
    case 'remittance-ria':
      return 'Ria Money Transfer';
    case 'remittance-western-union':
      return 'Western Union';
    default:
      return category;
  }
};

export const getCategoryLabel = (transaction) =>
  getCategoryTextLabel(transaction?.category);

export const getUser = (transaction) => {
  if (transaction?.user) {
    return (
      transaction.user.displayName ??
      transaction.user.phoneNumber ??
      transaction.user.email
    );
  }
  return transaction?.userUuid;
};

export const getExternalId = (transaction) => transaction?.externalId ?? '-';

export const getAgentName = (transaction) =>
  transaction?.agent.agentName ?? '-';

export const getAgentExternalId = (transaction) => {
  if (transaction?.user.authenticatedBy === 'EzeeMoney') {
    return transaction?.user.externalId ?? '-';
  }
  return '-';
};

export const getSources = () =>
  [
    'in_app',
    hasFeature(FEATURES.TRANSACTION_SOURCE_EZEEMONEY)
      ? 'ezeemoney_report'
      : null,
    hasFeature(FEATURES.TRANSACTION_SOURCE_SHEET) ? 'sheet' : null,
    hasFeature(FEATURES.TRANSACTION_SOURCE_SMS) ? 'sms' : null,
    hasFeature(FEATURES.TRANSACTION_SOURCE_USSD) ? 'ussd' : null,
  ].filter((x) => x !== null);

export const getSourceLabel = (source) => {
  switch (source) {
    case 'ezeemoney_report':
      return 'Ezeemoney Report';
    case 'in_app':
      return 'App';
    case 'sms':
      return 'SMS';
    case 'ussd':
      return 'USSD';
    case 'sheet':
      return 'Sheet';
    default:
      return source ?? '-';
  }
};

export const getSource = (transaction) => getSourceLabel(transaction?.source);

export const getSourceColor = (transaction) => {
  switch (transaction?.source) {
    case 'in_app':
      return '#F5FBF8'; // 525E75 '#EAF6F6'
    case 'sms':
      return '#F5FBF8';
    case 'ussd':
      return '#F5FBF8';
    case 'sheet':
      return '#F5FBF8';
    default:
      return 'grey';
  }
};

export const getSourceText = (transaction) => {
  switch (transaction?.source) {
    case 'in_app':
      return '#317159'; // #495C83 #5BC0F8
    case 'sms':
      return '#317159';
    case 'ussd':
      return '#317159';
    case 'sheet':
      return '#317159';
    default:
      return 'white';
  }
};

export const hasPosition = (transaction) => !!transaction.location;

export const getPosition = (transaction) => transaction.location?.coordinates;

export const getAmount = (transaction) => {
  return numberFormat(transaction.amount);
};

export const getCommission = (transaction) => {
  return numberFormat(transaction.commission);
};

export const getSuperAgentCommission = (transaction) => {
  return numberFormat(transaction.superAgentCommission);
};

export const getBalance = (transaction) => {
  return numberFormat(transaction.balance);
};

export const getRequestTime = (transaction) => {
  return dateFormat(parseInt(transaction?.requestTimestamp));
};

export const getUpdateTime = (transaction) => {
  return dateFormat(parseInt(transaction?.updateTimestamp));
};

export const getRecordTime = (transaction) => {
  return dateFormat(parseInt(transaction?.recordTimestamp));
};

export const getAndroidVersion = (transaction, isShort) => {
  if (
    transaction.androidVersion &&
    transaction.androidVersion.indexOf('::') > -1
  ) {
    const parts = transaction.androidVersion.split('::');
    if (isShort) {
      return parts[0];
    }
    return `${parts[0]} (SDK ${parts[1]})`;
  }
  return '-';
};

export const getAppVersion = (transaction) => transaction.appVersion;

export const getExtras = (transaction) => {
  try {
    const obj = JSON.parse(transaction?.transactionExtras);
    return JSON.stringify(obj, null, 4);
  } catch (err) {}

  return transaction?.transactionExtras || '-';
};

export const getActionId = (transaction) => transaction.actionId;

export const getParserTargets = () => ['SMS', 'USSD'];

export const getParserTelcos = () => {
  switch (import.meta.env?.VITE_ENV) {
    case 'staging_afrimoney_angola':
    case 'production_afrimoney_angola':
      return ['AO_AFRICELL'];
    case 'staging_afrimoney_gambia':
    case 'production_afrimoney_gambia':
      return ['GM_AFRICELL'];
    case 'staging_cyad_drc':
    case 'production_cyad_drc':
      return ['CD_VODACOM', 'CD_MPESA'];
    case 'staging_mobicom_zambia':
    case 'production_mobicom_zambia':
      return ['ZM_AIRTEL', 'ZM_MTN', 'ZM_ZAMTEL'];
    case 'staging_opareta':
    case 'production_opareta':
    default:
      return ['UG_AIRTEL', 'UG_EZEE', 'UG_MTN'];
  }
};

export const getParserStatuses = () => ['pending', 'succeeded', 'failed'];

export const getParserCategories = () =>
  [
    hasFeature(FEATURES.TRANSACTION_AIRTIME) ? 'airtime' : '',
    hasFeature(FEATURES.TRANSACTION_BUNDLE) ? 'bundle' : '',
    hasFeature(FEATURES.CUSTOMER_REGISTRATION) ? 'customer_registration' : '',
    hasFeature(FEATURES.TRANSACTION_CASH_IN) ? 'deposit' : '',
    hasFeature(FEATURES.LOAN) ? 'loan_repayment' : '',
    hasFeature(FEATURES.TRANSACTION_UG) ? 'commission' : '',
    hasFeature(FEATURES.TRANSACTION_UG) ? 'commission_transfer' : '',
    hasFeature(FEATURES.TRANSACTION_UG) ? 'loyalty-payment' : '',
    hasFeature(FEATURES.TRANSACTION_UG) ? 'loyalty-topup' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY) ? 'paybill' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-dstv' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-nwsc' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-startimes' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-tugende' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-umeme' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-yaka' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-uedcl' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-light' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-ura' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'paybill-water' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_UG) ? 'SC Transfer Comm To SC' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_AO) ? 'paybill-ende' : '',
    hasFeature(FEATURES.TRANSACTION_BILLPAY_AO) ? 'paybill-epal' : '',
    hasFeature(FEATURES.TRANSACTION_TRANSFER) ? 'transfer' : '',
    hasFeature(FEATURES.TRANSACTION_TRANSFER) ? 'transfer_in' : '',
    hasFeature(FEATURES.TRANSACTION_CASH_OUT) ? 'withdrawal' : '',
    hasFeature(FEATURES.FLOAT_CONTROL_REMITTANCES)
      ? 'Cash Out For Non Register'
      : '',
    hasFeature(FEATURES.FLOAT_CONTROL_REMITTANCES) ? 'remittance-bnb' : '',
    hasFeature(FEATURES.FLOAT_CONTROL_REMITTANCES)
      ? 'remittance-moneygram'
      : '',
    hasFeature(FEATURES.FLOAT_CONTROL_REMITTANCES) ? 'remittance-ria' : '',
    hasFeature(FEATURES.FLOAT_CONTROL_REMITTANCES)
      ? 'remittance-western-union'
      : '',
  ].filter((x) => x !== '');

export const getActionTelcos = () => [
  { name: 'Airtel UG', hni: '64101' },
  { name: 'MTN UG', hni: '64110' },
  { name: 'Warid UG', hni: '64122' },
  { name: 'MTN GH', hni: '62001' },
];

export const getActionTelcosMap = () => {
  return getActionTelcos().reduce((map, obj) => {
    map.set(obj.hni, obj.name);
    return map;
  }, new Map());
};

export const getTransactionExtras = (transaction) => {
  if (transaction && transaction.transactionExtras)
    return JSON.parse(transaction.transactionExtras);
  return {};
};

export const getTransactionFee = (transaction) => {
  if (transaction && transaction.transactionExtras) {
    const extras = JSON.parse(transaction.transactionExtras);
    return extras?.fee ?? 0;
  }

  return 0;
};

/**
 * Return formatted transaction amount.
 * @param {*} transaction
 * @returns
 */
export const getTransactionAmount = (transaction) =>
  numberFormat(transaction.amount - getTransactionFee(transaction));

/**
 * Get transaction statusMeaning or statusDescription as a boolean
 * @param {*} transaction
 * @returns boolean
 */
export const getStatusMeaning = (transaction) => {
  if (
    transaction.statusMeaning === 'true' ||
    transaction.statusMeaning === true ||
    transaction.statusMeaning === 'true' ||
    transaction.statusMeaning === true
  )
    return true;
  return false;
};

/**
 * Get Outbound status
 * @param {*} transaction
 * @returns
 */
export const getOutboundStatus = (transaction) => {
  if (getStatusMeaning(transaction)) return 'Succeeded';
  return 'Pending';
};

export const getOutboundStatusColor = (transaction) => {
  const status = getStatusMeaning(transaction);
  if (status) return '#BAE5D5';
  return '#F8E3DA';
};

export const getOutboundStatusText = (transaction) => {
  const status = getStatusMeaning(transaction);
  if (status) return '#317159';
  return '#996A13';
};

export const getExtraTelcoColor = (telco) =>
  telco?.includes('Airtel') ? '#4a4a4a' : '#bf1100';

export const getExtraTelcoBgColor = (telco) =>
  telco?.includes('Airtel') ? '#ffcf44' : '#ffcec9';
